import {mapMutations, mapActions, mapGetters} from "vuex";
//sections

import carousel from "@/modules/product-card/components/carousel/index.vue";
import {dynamicHead} from "../../mixins/dynamic-head";

export default {
    name: "product",
    components: {
        carousel
    },
    mixins: [dynamicHead],
    data() {
        return {}
    },
    created() {
        this.getCurrentProduct(this.$route.params.slug).then(() => {
            this.setPageItem(this.currentProduct)
            document.title = `${this.currentProduct.title}`

        })
        this.setRequestFlag(true);
        this.getDeliveriesType()
    },
    watch: {
        '$route.params.slug'() {
            this.getCurrentProduct(this.$route.params.slug)
        },
        'updatedProduct': function () {
            this.$router.push({name: 'product', params: {slug: this.updatedProduct.product.slug}}).then(() => {
            }).catch(() => {
                console.log()
            })
        }
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
            globalRequestsFlag: 'system/globalRequestsFlag',
            updatedProduct: 'card/setProduct',
            constructorLoading: 'constructors/constructorLoading'
        })
    },

    methods: {
        ...mapActions({
            getCurrentProduct: 'catalog/GET_PRODUCT',
            getDeliveriesType: 'card/GET_DELIVERY_TYPE'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        })
    }
}
